.Program_form {
  margin: 0 auto;
  width: 1200px;
  text-align: left;
}
.Program_form_title1 {
  font-size: 19px;
  color: #8ab059;
  margin: 70px 0 10px 0;
}
.Program_form_title2 {
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 90px;
}
.Program_form_check {
  margin-bottom: 50px;
}
.Program_form_check p {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 10px;
}
.Program_form_check_box {
  display: flex;
  align-items: center;
}
.Program_form_check_box_text {
  margin: 0 30px;
  font-size: 18px;
  cursor: pointer;
}
/* 체크박스 커스텀 */
.Program_form_check_box input[type="radio"],
.Program_form_check_box input[type="checkbox"] {
  display: none;
}
.Program_form_check_box input[type="radio"] + label,
.Program_form_check_box input[type="checkbox"] + label {
  display: inline-block;
  width: 26px;
  height: 26px;
  border: 1px solid #000000;
  position: relative;
  cursor: pointer;
}
.Program_form_check_box input:checked + label::after {
  content: "✔";
  font-size: 21px;
  width: 26px;
  height: 26px;
  text-align: center;
  position: absolute;
  left: -1px;
  top: -3.5px;
  color: white;
}
.Program_form_check_box input:checked + label {
  background-color: #8ab059;
  border: 1px solid #8ab059;
}
.Program_form_item {
  font-size: 20px;
  font-weight: 700;
  margin: 40px 0 10px 0;
  text-align: left;
}
.Program_form textarea {
  width: 99.5%;
  height: 235px;
  border: none;
  font-size: 18px;
  font-weight: 400;
  padding: 15px;
}
.Program_form_file input {
  display: none;
}
.Program_form_file {
  width: 99.5%;
  line-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: 700;
  margin: 45px 0;
}
.Program_form_file_title {
  width: 110px;
}
.Program_form_file_name {
  width: 870px;
  height: 56px;
  background-color: #d9d9d9;
  margin: 0 15px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
.Program_form_file_upload {
  width: 180px;
  height: 56px;
  background-color: #d9d9d9;
  cursor: pointer;
  text-align: center;
}
.Program_form_bottom {
  width: 99.5%;
  margin-bottom: 20px;
}
.Program_form_bottom_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Program_form_bottom_box_text {
  font-size: 18px;
  font-weight: 700;
}
.Program_form_submit {
  padding-top: 50px;
  padding-bottom: 300px;
  display: flex;
}
.Program_form_submit input {
  margin: 0 auto;
  width: 280px;
  height: 56px;
  line-height: 56px;
  border: 1px solid #8ab059;
  font-size: 22px;
  font-weight: 700;
  color: #8ab059;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
}
