.Portfolio_inner {
  min-height: 580px;
  margin: 0 auto;
  width: 1200px;
}
.Portfolio_title {
  font-size: 19px;
  color: #8ab059;
  margin: 70px 0 20px 0;
  text-align: left;
}
.Portfolio_serach_bar {
  width: 550px;
  display: flex;
  position: relative;
  margin-bottom: 40px;
  border-bottom: 1px solid;
}
.Portfolio_serach_bar input {
  width: 94%;
  border: none;
  background-color: transparent;
  font-size: 19px;
}
.Portfolio_serach_bar img {
  position: absolute;
  right: 0;
  top: -5px;
}
.Portfolio_filter {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.Portfolio_filter input[type="radio"] {
  display: none;
}
.Portfolio_filter input[type="radio"] + label,
.Portfolio_filter_input input[type="radio"] + label {
  width: 100px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #555555;
  font-size: 17px;
  cursor: pointer;
  display: block;
}
.Portfolio_filter input:checked + label {
  background-color: #8ab059;
  border: 1px solid #8ab059;
  color: white;
}
.Portfolio_filter_input:nth-child(odd) {
  margin: 0 20px;
}
.Portfolio_content {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 365px);
  gap: 30px 20px;
  margin-bottom: 200px;
  min-height: 400px;
}
.Portfolio_box {
  width: 100%;
  height: 400px;
  border: 1px solid #555555;
  position: relative;
}
.Portfolio_box_type {
  position: absolute;
  width: 85px;
  height: 33px;
  line-height: 33px;
  background-color: #ffffff;
  border: 1px solid #555555;
  font-size: 16px;
  margin: 15px;
}
.Portfolio_box_img {
  width: 100%;
  height: 216px;
  /* background-color: #ececec; */
  padding: 15px;
  line-height: 216px;
}
.Portfolio_box_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: bottom;
}
.Portfolio_box_text {
  text-align: left;
  padding: 20px 15px;
  font-size: 17px;
}
.Portfolio_box_text p:nth-child(1) {
  font-weight: 700;
}
.Portfolio_box_text p:nth-child(2) {
  margin: 10px 0 30px 0;
}
