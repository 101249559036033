.About_con1 {
  height: 580px;
  background-color: #fbfbfb;
  padding-top: 120px;
}
.About_con1 p:first-child {
  color: #8ab059;
  font-size: 24px;
  font-weight: 700;
  text-shadow: 0px 2px 2px rgba(158, 158, 158, 0.25);
}
.About_con1 p:nth-child(2) {
  font-size: 30px;
  font-weight: 700;
  color: #707070;
  margin-top: 50px;
}
.About_con1 p:nth-child(3) {
  font-size: 39px;
  font-weight: 700;
  margin-top: 25px;
}
.About_con1 img {
  margin-top: 100px;
}
.About_con2 {
  margin: 0 auto;
  width: 1200px;
  padding-top: 70px;
}
.About_con2_text {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.About_con2_circle {
  display: flex;
  justify-content: center;
  margin-top: 75px;
}
.About_con2_circle_box {
  display: flex;
  align-items: center;
}
.About_con2_circle_text {
  width: 180px;
  height: 180px;
  border: 1px solid #8ab059;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #8ab059;
  white-space: pre-wrap;
  line-height: 36px;
}
.About_con2_circle_box img {
  height: 2px;
  margin: 0 20px;
}
.About_con2_bottom_text {
  font-size: 17px;
  font-weight: 400;
  color: #aeaeae;
  text-align: left;
  margin: 50px 0 80px 0;
}
.About_con3 {
  margin: 0 auto;
  width: 1200px;
  padding-top: 60px;
  display: flex;
}
.About_con3_text {
  font-size: 23px;
  font-weight: 700;
  color: #8ab059;
  margin-right: 200px;
}
.About_con3_history_box {
  display: flex;
  margin-top: 50px;
  font-size: 19px;
  min-height: 90px;
}
.About_con3_history_box_year {
  color: #8ab059;
  margin: 0 70px 0 30px;
}
.About_con3_history_box_text {
  font-size: 16px;
  font-weight: 700;
  padding-top: 20px;
  text-align: left;
}
.About_con3_history_box_text p {
  margin: 20px 0;
  line-height: 30px;
}
.About_con4 {
  margin-top: 110px;
  background-color: #fbfbfb;
}
.About_con4_text {
  margin: 0 auto;
  width: 1200px;
  text-align: left;
  font-size: 23px;
  font-weight: 700;
  color: #8ab059;
  padding: 50px 0;
}
.About_con4 img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  vertical-align: bottom;
}
.About_con5 {
  margin: 0 auto;
  width: 1200px;
  padding-top: 130px;
}
.About_con5_box {
  display: flex;
  align-items: center;
}
.About_con5_box p {
  text-align: left;
  margin-left: 75px;
  font-size: 17px;
  font-weight: 700;
}
.About_con5_bottom_text {
  display: flex;
  justify-content: start;
  margin: 50px 0 130px 360px;
}
.About_con5_bottom_text p {
  width: 230px;
  height: 53px;
  line-height: 53px;
  background-color: #8ab059;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
