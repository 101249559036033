.News_inner {
  margin: 0 auto;
  width: 1200px;
  padding-bottom: 250px;
  min-height: 647px;
}
.News_title {
  font-size: 19px;
  color: #8ab059;
  margin: 70px 0 20px 0;
  text-align: left;
}
.pagination {
  margin: 30px 40px 50px 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  font-size: 19px;
}
.pagination li {
  cursor: pointer;
  margin: 5px;
}
.pagination li a {
  padding: 5px;
  color: black;
  text-decoration: none;
  border-radius: 50%;
  display: inline-block;
}
.pagination .active a {
  font-weight: 700;
  font-size: 19px;
}
