.Header_inner {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px 0;
  position: absolute;
  top: 0;
  z-index: 1;
}
.Header_inner img {
  width: 200px;
  height: 25px;
  margin-left: 55px;
  margin-right: 35px;
  cursor: pointer;
}
.Header_nav {
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 19px;
  font-weight: 600;
}
.Header_inner p {
  margin: 0 12px;
  cursor: pointer;
}
.Header_nav p {
  color: #ffffff;
}
.Header_nav .active p {
  color: #8ab15a;
}
/* 메뉴 클릭 시 헤더 배경색 변경 */
.Header_inner:has(.active) {
  background-color: #30435f;
  position: relative;
}
