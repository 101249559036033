.Program_con1 {
  height: 580px;
  background-color: #fbfbfb;
  padding-top: 120px;
}
.Program_con1 > p:nth-child(1) {
  color: #8ab059;
  font-size: 24px;
  font-weight: 700;
}
.Program_con1 > p:nth-child(2) {
  font-size: 28px;
  /* font-weight: 700; */
  /* color: #707070; */
  margin-top: 80px;
}
.Program_con1 > p:nth-child(3) {
  font-size: 44px;
  font-weight: 700;
  margin-top: 15px;
}
.Program_con1_btn {
  margin-top: 150px;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Program_con1_btn p {
  width: 270px;
  height: 56px;
  line-height: 56px;
  margin: 0 15px;
  cursor: pointer;
}
/* .Program_con1_btn p:first-child {
  border: 1px solid #8ab059;
  color: #8ab059;
} */
.Program_con1_btn p {
  background-color: #8ab059;
  color: white;
}
.Program_con2 {
  margin: 0 auto;
  width: 1200px;
  padding-top: 120px;
}
.Program_con2_box {
  display: flex;
  align-items: center;
  margin-bottom: 130px;
}
.Program_con2_box_text {
  width: 390px;
}
.Program_con2_box_title {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}
.Program_con2_box_title p {
  font-size: 30px;
  font-weight: 700;
  margin-left: 25px;
}
.Program_con2_box_detail_text {
  /* width: 270px; */
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
}
.Program_con2_box_detail_text_bottom {
  font-size: 11px;
  font-weight: 400;
  color: #aeaeae;
  margin-top: 50px;
  text-align: left;
}
.Program_con2_box_con2_text {
  justify-content: flex-end;
}
.Program_con2_box_con2_detail {
  margin-left: 85px;
  /* display: flex; */
}
/* 슬라이드 애니메이션 */
.Program_con2_box_slide {
  width: 785px;
  height: 380px;
  border: 1px solid #8ab059;
  box-shadow: 0px 4px 10px rgba(182, 182, 182, 0.25);
  overflow: hidden;
  position: relative;
}
.Program_con2_box_slide_box {
  display: flex;
  position: absolute;
}
.Program_con2_box_slide_box_content {
  width: 785px;
  height: 380px;
  /* transition: all 500ms; */
  /* transform: translateX(-785px); */
  /* transform: translateX(0); */
  padding: 50px 75px;
  text-align: left;
}
.Program_con2_box_slide_box_content_title {
  font-size: 23px;
  margin-bottom: 40px;
  font-weight: 700;
}
.Program_con2_box_slide_box_content_sub_title {
  font-size: 17px;
  margin-bottom: 40px;
  /* color: #aeaeae; */
  line-height: 32px;
  white-space: pre-wrap;
}
.Program_con2_box_slide_box_content_text {
  font-size: 17px;
  line-height: 32px;
}
.Program_con2_box_slide_box_content img {
  width: 90px;
  height: 90px;
  margin-right: 40px;
}
.Program_con2_box_slide_arrow_btn {
  position: absolute;
  top: 45%;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
.Program_con2_box_slide_arrow_btn img {
  margin: 0 350px;
  cursor: pointer;
}
.Program_con2_box_slide_dot_btn {
  width: 100%;
  margin-top: 335px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1px;
}
.Program_con2_box_slide_dot_btn div {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
}
.dot_on {
  background-color: #8ab059;
}
.dot_no {
  background-color: #d9d9d9;
}
.Program_con3 {
  background-color: #fbfbfb;
  padding: 110px 0;
}
.Program_con3 p:first-child {
  font-size: 30px;
  font-weight: 700;
  color: #8ab059;
  margin-bottom: 40px;
}
.Program_con3 p:nth-child(2) {
  width: 1210px;
  margin: 0 auto;
  font-size: 19px;
  font-weight: 700;
  text-align: left;
  line-height: 35px;
  margin-bottom: 80px;
}
.Program_con3 p:last-child {
  margin: 0 auto;
  width: 270px;
  height: 54px;
  line-height: 54px;
  background-color: #8ab059;
  color: white;
  font-size: 19px;
  cursor: pointer;
}
