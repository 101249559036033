* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Pretendard";
  font-style: normal;

  text-decoration: none;
}
input:focus,
textarea:focus {
  outline: none;
}
textarea {
  resize: none;
}
textarea {
  background-color: #d9d9d9;
}
input {
  background-color: #d9d9d9;
}
input:disabled {
  background-color: #efefef4d !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App {
  width: 100vw;

  min-width: 1600px;
  max-width: 2700px;

  height: 100vh;

  text-align: center;
  position: relative;

  overflow-y: scroll;
}
.Admin_App {
  display: flex;
}
.Router_box {
  width: 100%;

  height: 100%;

  min-height: 950px;
  max-height: 1500px;

  background-color: #ebebeb;

  padding: 20px;
}
.App::-webkit-scrollbar {
  display: none;
}
/* 에러 페이지 */
.not_Found_page {
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 99;
  padding-top: 40px;
}
.not_Found_page h3 {
  margin: 20px 0;
  display: inline-block;
}
