.Space_inner {
  margin: 0 auto;
  width: 1200px;
}
.Space_title {
  font-size: 19px;
  color: #8ab059;
  margin: 70px 0 10px 0;
}
.Space_titl2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 30px;
}
.Space_titl3 {
  font-size: 20px;
  margin-bottom: 60px;
}
.Space_titl4 {
  font-size: 17px;
  font-weight: 700;
  color: #555555;
  margin-bottom: 40px;
}
.Space_title,
.Space_titl2,
.Space_titl3,
.Space_titl4 {
  text-align: left;
}
.Space_inner_content {
  width: 100%;
  height: 666px;
  background-color: #ececec;
  margin-bottom: 80px;
  padding: 30px;
}
.Space_inner_content_title {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}
.Space_inner_content_img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.Space_inner_content_space_img {
  width: 400px;
  height: 560px;
}
.Space_inner_content_p {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.Space_inner_content_p p {
  width: 190px;
  height: 54px;
  line-height: 54px;
  cursor: pointer;
  border: 1px solid #8ab059;
  color: #8ab059;
  background-color: white;
  font-size: 19px;
  /* font-weight: 700; */
}
/* 슬라이드 */
.Space_inner_content_box {
  margin-right: 85px;
}
.Space_inner_content_slide {
  width: 550px;
  height: 450px;
  /* border: 1px solid #8ab059; */
  overflow: hidden;
  position: relative;
}
.Space_inner_content_slide_box {
  display: flex;
  position: absolute;
}
.Space_inner_content_slide_content {
  /* transition: all 500ms;
  transform: translateX(-0); */
  /* transform: translateX(0); */
}
.Space_inner_content_slide_content img {
  width: 550px;
  height: 450px;
  object-fit: cover;
  vertical-align: bottom;
}
.Space_inner_content_slide_content_arrow_btn {
  position: absolute;
  top: 45%;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
.Space_inner_content_slide_content_arrow_btn img {
  margin: 0 230px;
  cursor: pointer;
}
.Space_inner_seat_content {
  display: grid;
  grid-template-columns: repeat(4, 290px);
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 200px;
}
.Space_inner_seat_content_name {
  text-align: left;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}
.Space_inner_seat_content_img {
  height: 285px;
  background-color: #d9d9d9;
  position: relative;
}
.Space_inner_seat_content_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
.Space_inner_seat_content_use {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0.8;
  background-color: gray;
}
.Space_inner_seat_content_img p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: 700;
  padding: 5px 25px;
  border: 1px solid;
}
.space_not_seat {
  height: 500px;
}
/* 로그인 모달창 */
.space_login_modal {
  width: 450px;
  height: 330px;
  position: fixed;
  top: 20%;
  left: 55%;
  transform: translate(-55%, -15%);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  background-color: white;
  z-index: 99;
}
.space_login_box {
  margin-top: 20px;
}
.space_login_box_input {
  width: 100px;
  margin-left: 90px;
  margin-bottom: 20px;
}
.space_login_box_input p {
  float: left;
  font-size: 18px;
}
.space_login_box_input input {
  width: 270px;
  height: 40px;
  border: 0.5px solid #aeaeae;
  background-color: #fffafa;
  padding-left: 10px;
  font-size: 17px;
}
.login_btn {
  width: 270px;
  height: 40px;
  border: 0.5px solid #aeaeae;
  background-color: black;
  cursor: pointer;
  margin-top: 15px;
  font-size: 18px;
  color: white;
}
.login_btn:hover {
  background-color: #696969;
}
