.reservation_form_guide {
  width: 100%;
  height: 450px;
  border: 0.5px solid #aeaeae;
  margin-bottom: 100px;
  overflow: auto;
  text-align: left;
  padding: 40px;
}
.reservation_form_guide div {
  margin: 20px 0;
  font-size: 15px;
}
.reservation_form_guide p {
  line-height: 26px;
}
.reservation_form {
  margin-bottom: 50px;
}
.reservation_form_input {
  display: flex;
  align-items: center;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.reservation_form_input p {
  width: 180px;
  height: 55px;
  line-height: 55px;
  font-size: 17px;
  background-color: #f4f4f4;
  display: block;
}
.reservation_form_input input {
  width: 300px;
  height: 35px;
  border: 0.5px solid #aeaeae;
  background-color: #fffafa;
  padding-left: 10px;
  margin-left: 10px;
  font-size: 16px;
  margin-right: 100px;
}
.reservation_form_input input:disabled {
  background-color: #dcdcdc !important;
}
.reservation_form_btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 150px;
}
.reservation_form_btn button {
  width: 65px;
  height: 35px;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  cursor: pointer;
}
.reservation_form_btn button:nth-child(2) {
  margin-left: 10px;
  background-color: #0d7ac0;
  color: white;
}
