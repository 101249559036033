.Admin_inner {
  height: 100%;
  min-width: 1159px;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* position: relative; */
  overflow-y: scroll;
}
.Admin_inner::-webkit-scrollbar {
  width: 11px;
}
.Admin_inner::-webkit-scrollbar-thumb {
  height: 10%;
  background: #1c2b55;
}
.Admin_inner::-webkit-scrollbar-track {
  background: #d9d9d9;
}
/* 수정버튼 */
.Admin_edit_btn {
  text-align: left;
  margin: 15px;
}
.Admin_edit_btn button {
  width: 80px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background-color: #90ee90;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: black;
}
/* 홈 이미지 및 텍스트 */
.Admin_Home_main {
  width: 100%;
  height: 782px;
  position: relative;
}
.Admin_Home_main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
.Admin_Home_main_text {
  color: #ffffff;
  position: absolute;
  top: 35.5%;
  left: 19%;
  text-align: left;
}
.Admin_Home_main_text p:first-child {
  font-size: 80px;
  font-weight: 700;
}
.Admin_Home_main_text p:last-child {
  margin-top: 20px;
  font-size: 34px;
  font-weight: 400;
}
.Admin_Home_team {
  width: 100%;
  height: 500px;
  margin-bottom: 30px;
}
.Admin_Home_team img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
/* 수정 모달창 */
.Admin_edit_modal {
  width: 630px;
  /* min-height: 400px; */
  position: absolute;
  top: 20%;
  left: 55%;
  transform: translate(-55%, -15%);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  background-color: white;
  z-index: 99;
}
.Admin_edit_modal_inner_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  font-size: 20px;
  color: #333;
}
.Admin_edit_modal_inner_top img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.Admin_edit_modal_inner_form {
  padding: 10px 15px;
}
.Admin_edit_modal_inner_form_input input[type="file"] {
  display: none;
}
.Admin_edit_modal_inner_form_input {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 10px 0;
}
.Admin_edit_modal_inner_form_input_title {
  min-width: 18%;
  font-size: 16px;
}
.Admin_edit_modal_inner_form_input input {
  width: 84%;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  color: #555;
  padding: 10px;
  font-size: 15px;
}
.Admin_edit_modal_inner_form_input textarea {
  width: 84%;
  height: 120px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  color: #555;
  padding: 10px;
  font-size: 15px;
}
.Admin_edit_modal_inner_form_input select {
  min-width: 60px;
  border: 1px solid;
}
.Admin_edit_modal_inner_form_input label {
  width: 84%;
  height: 34px;
  line-height: 34px;
  background-color: #555;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  color: #555;
  font-size: 15px;
  cursor: pointer;
}
.Admin_edit_modal_inner_btn {
  border-top: 1px solid #e5e5e5;
  padding: 15px;
  text-align: right;
}
.Admin_edit_modal_inner_btn button {
  border: 1px solid #e5e5e5;
  width: 70px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}
.Admin_edit_modal_inner_btn button:first-child {
  background-color: white;
  margin-right: 10px;
}
.Admin_edit_modal_inner_btn button:last-child {
  background-color: green;
  color: white;
}
.Admin_edit_modal_img {
  width: 383px;
  height: 216px;
  margin-left: 110px;
}
.Admin_edit_modal_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: bottom;
}
