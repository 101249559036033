.Contact_us_form_inner {
  margin: 0 auto;
  width: 1200px;
}
.Contact_us_form_title {
  font-size: 19px;
  color: #8ab059;
  margin: 70px 0 20px 0;
  text-align: left;
}
.Contact_us_form_title2 {
  font-size: 33px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 80px;
  white-space: pre-wrap;
}
.Contact_us_form_inner textarea {
  width: 99.5%;
  height: 235px;
  border: none;
  font-size: 18px;
  font-weight: 400;
  padding: 15px;
}
