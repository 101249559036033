.About_form {
  margin: 0 auto;
  width: 1200px;
}
.About_form_text {
  text-align: left;
  font-size: 21px;
  font-weight: 700;
  color: #555555;
  margin: 110px 0 60px 0;
}
.About_form_title {
  display: flex;
  align-items: center;
  margin-bottom: 130px;
}
.About_form_title p {
  text-align: left;
  margin-left: 75px;
  font-size: 18px;
  font-weight: 700;
}
.About_form_box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-bottom: 50px;
}
/* 메일 폼 */
.About_form_box_input {
  width: 50%;
}
.About_form_box_input_box {
  height: 120px;
}
.About_form_box_input p {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}
.About_form_box_input input {
  width: 99%;
  height: 56px;
  border: none;
  font-size: 18px;
  font-weight: 400;
  padding-left: 15px;
  /* color: #707070; */
}
.About_form_box_input_margin {
  margin-top: 40px;
}
.About_form_box_input_msg {
  font-size: 15px !important;
  color: red;
}
/* 제출하기 */
.About_form_submit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 0 230px 0;
}
.About_form_submit p:first-child {
  font-size: 18px;
  font-weight: 700;
}
.About_form_submit input {
  width: 160px;
  height: 52px;
  line-height: 53px;
  cursor: pointer;
  border: 1px solid #8ab059;
  font-size: 18px;
  color: #8ab059;
  background-color: transparent;
}
/* 개인정보 동의 */
.About_form_Privacy {
  width: 100%;
  height: 400px;
  background-color: #f2f2f2;
  overflow: auto;
  margin-bottom: 20px;
}
.About_form_Privacy_box {
  margin: 40px;
}
.About_form_Privacy_p1,
.About_form_Privacy_p2,
.About_form_Privacy_p3 {
  text-align: left;
  color: gray;
  font-size: 15px;
}
.About_form_Privacy_p2 {
  font-size: 17px;
  margin-top: 40px;
}
.About_form_Privacy_p3 {
  margin-bottom: 50px;
}
.About_form_Privacy_table {
  margin: 40px auto;
  border-collapse: collapse;
}
.About_form_Privacy_table th {
  background-color: #ddd;
  font-size: 15px;
  color: gray;
  padding: 10px;
  border: 1px solid #666;
  text-align: center;
}
.About_form_Privacy_table td {
  font-size: 14px;
  color: gray;
  padding: 30px;
  border: 1px solid #666;
}
