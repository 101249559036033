.Footer_inner {
  width: 100%;
  height: 230px;
  background-color: #30425f;
  padding: 40px 350px 0 350px;
}
.Footer_inner_text {
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 30px;
}
.Footer_inner_text div:first-child {
  width: 29%;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
}
.Footer_inner_text div:nth-child(2) {
  width: 21%;
  text-align: left;
  font-size: 14px;
}

.Footer_inner_text div:nth-child(3) {
  width: 25%;
  text-align: left;
  font-size: 15px;
}
.Footer_inner_text div:nth-child(2) p:first-child,
.Footer_inner_text div:nth-child(3) p:first-child {
  margin-bottom: 25px;
  font-size: 20px;
}
.Footer_inner_copy {
  font-size: 13px;
  color: #aeaeae;
  text-align: right;
}
