.Home_inner {
}
.Home_inner_main {
  /* width: 1920px; */
  width: 100%;
  height: 782px;
  position: relative;
}
.Home_inner_main img {
  /* width: 1920px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
.Home_inner_main_text {
  color: #ffffff;
  position: absolute;
  top: 35.5%;
  left: 19%;
  text-align: left;
}
.Home_inner_main_text p:first-child {
  font-size: 70px;
  font-weight: 700;
}
.Home_inner_main_text p:last-child {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 400;
}
.inner_sub {
  margin: 0 auto;
  width: 1200px;
}
.Home_inner_sub_text {
  margin: 105px 0 70px 0;
}
.Home_inner_sub_text p {
  margin-top: 10px;
  font-size: 34px;
  font-weight: 700;
  color: #000000;
  letter-spacing: -1px;
}
.Home_inner_program_click {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.Home_inner_program_click p {
  font-size: 20px;
  font-weight: 400;
  color: #aeaeae;
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
}
.Home_inner_program {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 110px;
}
.Home_inner_program_box {
  width: 100%;
}
.Home_inner_program_box:nth-child(2) {
  margin: 0 15px;
}
.Home_inner_program_main {
  width: 100%;
  height: 320px;
  border: 1px solid #8ab059;
  margin-bottom: 30px;
  /* cursor: pointer; */
}
/* .Home_inner_program_main p {
    transition: all 300ms;
  }
  .Home_inner_program_main:hover .Home_inner_program_box_sub_title p,
  .Home_inner_program_main:hover .Home_inner_program_box_title p,
  .Home_inner_program_main:hover .Home_inner_program_box_text p {
    transform: scale(1.1);
  } */
.Home_inner_program_box_sub_title {
  text-align: left;
  font-size: 15px;
}
.Home_inner_program_box_sub_title p {
  display: inline-block;
  margin: 15px;
  padding: 5px 10px;
  background-color: #c4dda5;
  color: #2f4611;
}
.Home_inner_program_box_title {
  font-size: 34px;
  font-weight: 700;
  /* margin: 30px; */
  margin: 30px 0;
  letter-spacing: -3px;
}
.Home_inner_program_box_text {
  color: #aeaeae;
  font-size: 16px;
  white-space: pre-wrap;
  line-height: 30px;
  letter-spacing: -1px;
}
.Home_inner_program_sub {
  text-align: left;
  padding: 10px 20px 15px 20px;
}
.Home_inner_program_box_sub {
  display: flex;
  align-items: center;
}
.Home_inner_program_box_sub p:first-child {
  width: 100px;
  color: #aeaeae;
  font-size: 19px;
}
.Home_inner_program_box_sub p:last-child {
  color: #000000;
  font-size: 18px;
  margin-left: 30px;
  font-weight: 600;
}
.Home_inner_sub_news {
  width: 100%;
  padding: 130px 0;
  background-color: #fbfbfb;
}
.Home_inner_sub_text2 {
  letter-spacing: -1px;
  font-size: 39px;
  text-align: left;
  font-weight: 700;

  margin-bottom: 70px;
}
.Home_inner_sub_text2_color {
  color: #8ab059;
}
/* 뉴스 */
.Home_inner_news_box table tbody {
  width: 1196px;
  min-height: 270px;
  padding: 35px 20px;
  display: block;
  border-bottom: 1px solid #aeaeae;
}
.Home_inner_news_box table tbody:first-child {
  border-top: 1px solid #aeaeae;
}
.Home_inner_news_top {
  height: 39px;
  font-size: 17px;
}
.Home_inner_news_img {
  width: 21%;
  height: 186px;
}
.Home_inner_news_img img {
  width: 240px;
  height: 186px;
  object-fit: contain;
  vertical-align: bottom;
}
.Home_inner_news_top_td2 {
  min-width: 200px;
  padding-left: 18px;
  text-align: left;
}
.Home_inner_news_top_td3 {
  width: 320px;
}
.Home_inner_news_top_td3 p {
  display: inline;
}
.Home_inner_news_top_td4 {
  width: 380px;
  text-align: left;
}
.Home_inner_news_top_td4 p {
  display: inline;
}
.Home_inner_news_top_td3 p:last-child,
.Home_inner_news_top_td4 p:last-child {
  font-size: 14px;
  color: #707070;
}
.Home_inner_news_top_td5 {
  width: 85px;
  background-color: #ececec;
  border: 1px solid #aeaeae;
}
.Home_inner_news_title {
  height: 53px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}
.Home_inner_news_title p {
  padding: 0px 0px 5px 20px;
  cursor: pointer;
  display: inline;
  letter-spacing: -1px;
}
.Home_inner_news_content {
  height: 58px;
  font-size: 18px;
  color: #aeaeae;
  text-align: left;
  letter-spacing: -1px;
}
.Home_inner_news_content td {
  padding: 0px 0px 5px 20px;
  cursor: pointer;
}
.Home_inner_news_date_created {
  height: 30px;
  font-size: 15px;
  color: #aeaeae;
  text-align: left;
}
.Home_inner_news_date_created td {
  padding: 0px 0px 0px 20px;
  height: 30px;
}
/* 푸터 이미지, 텍스트*/
.Home_inner_footer_img {
  width: 100%;
  position: relative;
}
.Home_inner_footer_img img {
  width: 100%;
  height: 100%;
  display: block;
}
.Home_inner_footer_text {
  position: absolute;
  top: 26%;
  left: 18.5%;
  color: white;
  text-align: left;
  font-size: 27px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -1px;
}
.Home_inner_footer_btn {
  width: 220px;
  height: 56px;
  line-height: 58px;
  background-color: #8ab059;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  margin-top: 80px;
}
.Home_inner_footer_btn p {
  color: white;
}
.Loading {
  margin-top: 100px;
  height: 1000px;
}
