.Login_inner {
  height: 100%;
  padding-top: 150px;
}
.Login_box input {
  background-color: white;
  width: 300px;
  height: 45px;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.Login_input1 {
  margin: 45px 0 0 0;
}
.Login_btn {
  width: 300px;
  height: 47px;
  margin-top: 15px;
  border: none;
  background-color: #8ab059;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.Login_btn:hover {
  opacity: 80%;
}
