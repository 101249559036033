.date_input {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date_input input {
  font-size: 23px;
  background-color: transparent;
  border: none;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.date_input input:focus {
  outline: none;
}
.date_input .reserved_ok_btn {
  width: 100px;
  height: 40px;
  font-size: 16px;
  background-color: #0d7ac0;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}
.date_input .reserved_cancel_btn {
  width: 130px;
  height: 40px;
  font-size: 16px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}
.state_btn {
  display: flex;
  margin-top: 30px;
}
.state_color:nth-child(1) {
  width: 30px;
  border-radius: 8px;
  background-color: #bddd94;
}
.state_color:nth-child(3) {
  width: 30px;
  border-radius: 8px;
  background: #ff2f36;
}
.state_color:nth-child(5) {
  width: 30px;
  border-radius: 8px;
  background: #ccc;
}
.state_text {
  margin: 0 15px 0 5px;
}
/* 예약현황 테이블 */
.reservation-table {
  border-collapse: collapse;
  width: 100%;
  margin: 40px 0 120px 0;
}
.reservation-table th {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}
.reservation-table tbody td,
.reservation-table tbody th {
  border: 1px solid #ccc;
  padding: 15px;
  text-align: center;
  cursor: pointer;
}
.reservation-table td:hover {
  background-color: orange;
}
.selected {
  background-color: orange;
}
.reserved {
  background-color: #bddd94 !important;
  /* border: none !important; */
}
.my_reserved {
  background: #ff2f36 !important;
  /* border: none !important; */
}
.endTime {
  background-color: gray;
  opacity: 0.3;
}
.reservation_p {
  text-align: left;
  font-size: 15px;
  margin-bottom: 100px;
}
/* 예약 취소 테이블 */
.reserved_cancel_modal {
  width: 950px;
  height: 560px;
  position: fixed;
  top: 20%;
  left: 55%;
  transform: translate(-55%, -15%);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  background-color: white;
  z-index: 99;
  overflow: auto;
}
.reservation_cancel_table {
  border-collapse: collapse;
  width: 100%;
}
.reservation_cancel_table th {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}
.reservation_cancel_table thead th {
  background-color: #bddd94;
}
.reservation_cancel_table tbody td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
}
.btn_td button {
  width: 60px;
  height: 30px;
  margin: 0 3px;
  font-size: 14px;
  cursor: pointer;
}
.btn_td button:nth-child(1) {
  background-color: white;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  color: black;
}
.btn_td button:nth-child(2) {
  background-color: #0d7ac0;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  color: white;
}
