/* 보여줄 아이템 갯수 */
.table_top {
  display: flex;
  align-items: center;
  margin: 15px;
}
.table_show_item {
  display: flex;
  align-items: center;
}
.table_show_item input {
  width: 75px;
  height: 35px;
  border-radius: 20px;
  text-align: center;
  font-size: 20px;
  border: 1px solid gray;
  background-color: white;
}
.table_show_item p {
  margin: 0 10px;
  font-size: 15px;
  color: gray;
}
/* .table_show_item input::-webkit-outer-spin-button,
.table_show_item input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
.MuiTableContainer-root {
  min-height: 666px;
  max-height: 666px;
}
/* 테이블 헤더 */
tr .MuiTableCell-head {
  min-width: 130px;
  height: 66px;
  border-top: 1px solid #ccc;
  border-bottom: 2px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 18px;
  font-weight: 700;
  /* padding: 20px; */
  padding: 0;
}
/* 테이블 바디 */
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
  height: 60px;
  font-size: 16px;
  /* padding: 10px 0; */
  padding: 0;
  border-right: 1px solid #ccc;
}
.MuiTableContainer-root th,
.MuiTableContainer-root td {
  text-align: center;
}
/* 수정 / 삭제 버튼 */
.table_edit_btn {
  width: 180px;
}
.table_edit_btn button {
  width: 38%;
  height: 36px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
  border-radius: 5px;
}
.table_edit_btn button:nth-child(1) {
  background-color: #f0ad4e;
}
.table_edit_btn button:nth-child(2) {
  background-color: #d9534f;
}
.table_order_btn {
  width: 100px;
}
.table_order_btn button {
  width: 38%;
  height: 36px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  color: black;
  font-size: 25px;
  font-weight: 700;
  border: 1px solid gray;
}
.Admin_table_textarea {
  line-height: 50%;
}
.Admin_table_note {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  border: none;
  background-color: transparent;
}
/* 테이블에 이미지 부분 */
.table_img {
  width: 200px;
}
.table_img img {
  /* width: 100%; */
  width: 180px;
  height: 200%;
  object-fit: contain;
  vertical-align: bottom;
}
