.Admin_Header {
  width: 250px;
  height: 100vh;
  min-height: 950px;
  max-height: 1500px;
  background-color: #30435f;
}
.Admin_Header img {
  width: 200px;
  margin-top: 10px;
  margin-bottom: 50px;
}
.Admin_Header_nav p {
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  margin: 20px 0;
}
.Admin_Header_nav .active p {
  color: #8ab15a;
}
.Admin_Header_Logout {
  margin-top: 280px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
}
