/* 수정버튼 */
.Admin_add_btn {
  text-align: left;
  margin: 15px;
}
.Admin_add_btn button {
  width: 80px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background-color: #5bc0de;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: white;
}
.Admin_table_title {
  text-align: left;
  margin: 40px 15px 15px 15px;
  font-size: 22px;
  font-weight: 700;
}
