.Contact_us_inner {
  margin: 0 auto;
  width: 1200px;
}
.Contact_us_title {
  font-size: 19px;
  color: #8ab059;
  margin: 70px 0 20px 0;
  text-align: left;
}
.Contact_us_titl2 {
  font-size: 22px;
  text-align: left;
  margin-bottom: 100px;
}
.Contact_us_titl3 {
  font-size: 21px;
  font-weight: 700;
  color: #555555;
  margin-bottom: 50px;
}
.Contact_us_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Contact_us_content input[type="radio"] {
  display: none;
}
.Contact_us_box {
  width: 370px;
  height: 365px;
  border: 1px solid #8ab059;
  margin: 0 20px;
  text-align: left;
  padding: 55px;
  cursor: pointer;
}
.Contact_us_box_title {
  font-size: 30px;
  font-weight: 700;
  white-space: pre-wrap;
  border-bottom: 2px solid #555555;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.Contact_us_box_text {
  font-size: 17px;
  line-height: 30px;
}
.Contact_us_content input:checked + label .Contact_us_box {
  background-color: #8ab059;
  color: white;
}
.Contact_us_content
  input:checked
  + label
  .Contact_us_box
  .Contact_us_box_title {
  border-bottom: 2px solid #ffffff;
}
.Contact_us_file {
  display: flex;
  align-items: center;
  margin: 10px 0 40px 0;
}
.Contact_us_file p:last-child {
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
  border-bottom: 1px solid;
}
.Contact_us_btn {
  display: flex;
  justify-content: end;
  margin-bottom: 250px;
}
.Contact_us_btn p {
  width: 100px;
  height: 55px;
  line-height: 55px;
  border: 1px solid #8ab059;
  color: #8ab059;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}
