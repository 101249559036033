.Partners_inner {
  min-height: 600px;
  margin: 0 auto;
  width: 1200px;
}
.Partners_title {
  font-size: 19px;
  color: #8ab059;
  margin: 70px 0 20px 0;
  text-align: left;
}
.Partners_box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 90px;
}
.Partners_box:last-child {
  margin-bottom: 230px;
}
.Partners_content {
  display: grid;
  grid-template-columns: repeat(5, 180px);
  align-items: center;
  gap: 25px;
}
.Partners_box p {
  width: 100%;
  height: 115px;
  line-height: 115px;
  font-size: 20px;
  font-weight: 700;
}
.Partners_logo {
  width: 100%;
  height: 115px;
}
.Partners_logo img {
  /* width: 100%;
  height: 100%; */
  width: 180px;
  height: 115px;
  object-fit: contain;
  vertical-align: bottom;
}
